import { getTokenJWT } from "../../utils";

//Get all scientific publications
const getSP = {
  getSP() {
    return fetch(`${window.$apiUrl}/scientific-publications?_limit=-1`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": getTokenJWT(),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonReponse) => {
        return jsonReponse;
      })
      .catch((error) => console.error(" Error retrieving publication -->", error));
  },
};

export default getSP;
