import { getTokenJWT } from "../../utils";

//Get all scientific publications
const getEM = {
  getEM() {
    return fetch(`${window.$apiUrl}/meetings?_limit=-1`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": getTokenJWT(),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonReponse) => {
        return jsonReponse;
      })
      .catch((error) => console.error(" Error retrieving event -->", error));
  },
};

export default getEM;
