import deleteEM from "./deleteEM";
import { getTokenJWT } from "../../utils";
import ErrorToast from "../../../components/errortoast/ErrorToast";

//UPDATE IMAGE & CONTENT
const updateEM = {
  async update(oldData, newData, imgData) {
    //delete image
    deleteEM.deleteImage(oldData);

    //upload image
    let imageId;
    let formData = new FormData();
    formData.append("files", imgData);

    const requestOptions = {
      method: "POST",
      headers: {
        "Authorization": getTokenJWT(),
      },
      body: formData,
      redirect: "follow",
    };

    let data;
    try {
      const response = await fetch(`${window.$apiUrl}/upload`, requestOptions);
      const result = await response.json();
  
      imageId = result[0]._id;
      data = await this.updateEM(oldData, newData, imageId).then((result) => {
        return result;
      });
    } catch(error) {
      console.error(" Error updating event -->", error)
    }

    return data;
  },

  updateEM(oldData, newData, imageId) {
    //Headers
    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Authorization", getTokenJWT())

    //Body
    let urlencoded = new URLSearchParams();
    urlencoded.append("rowId", JSON.stringify(newData.tableData));
    urlencoded.append("startDate", newData.startDate);
    urlencoded.append("endDate", newData.endDate);
    urlencoded.append("eventName", newData.eventName);
    urlencoded.append("objective", newData.objective);
    urlencoded.append("reachedAudience", newData.reachedAudience);
    urlencoded.append("link", newData.link);
    urlencoded.append("photo", imageId);

    for (let i = 0; i < newData.eventType.length; i++) {
      urlencoded.append("eventType", newData.eventType[i]);
    }

    for (let i = 0; i < newData.partners.length; i++) {
      urlencoded.append("partners", newData.partners[i]);
    }

    for (let j = 0; j < newData.targetAudience.length; j++) {
      urlencoded.append("targetAudience", newData.targetAudience[j]);
    }

    const requestOptions = {
      method: "PUT",
      headers: headers,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(`${window.$apiUrl}/meetings/` + oldData._id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode && result.error) {
          console.error(" Error updating event -->", result);
          ErrorToast(result.error);
          return;
        } else return result;
      })
      .catch((error) => console.error(" Error updating event -->", error));
  },
};

export default updateEM;
