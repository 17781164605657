import getCA from "./getCA";
import { getTokenJWT } from "../../utils";

const deleteCA = {
  //Delete row data from database
  deleteCA(oldData) {
    fetch(`${window.$apiUrl}/communications/` + oldData.id, {
      method: "DELETE",
      headers: {
        "Authorization": getTokenJWT()
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.deleteImage(oldData);
      })
      .catch((error) => console.error(" Error deleting communication activity -->", error));
  },

  //Delete image from media library
  deleteImage(oldData) {
    fetch(`${window.$apiUrl}/upload/files/` + oldData.photo.id, {
      method: "DELETE",
      headers: {
        "Authorization": getTokenJWT()
      },
    })
      .then((res) => res.json())
      .then((res) => res)
      .catch((error) => console.error(" Error deleting image -->", error));
  },

  async deleteFileImage(oldImgData) {
    let dataCA = await getCA.getCA().then((result) => {
      return result;
    });

    for (let i = 0; i < dataCA.length; i++) {
      let data = dataCA[i];

      if (data.photo !== null) {
        if (data.photo.name === oldImgData.path) {
          this.deleteCA(data);
        }
      }
    }
  },
};

export default deleteCA;
