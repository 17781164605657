import deleteSP from "./deleteSP";
import { getTokenJWT } from "../../utils";
import ErrorToast from "../../../components/errortoast/ErrorToast";

//UPDATE IMAGE & CONTENT
const updateSP = {
  async update(oldData, newData, imgData) {
    //delete image
    deleteSP.deleteImage(oldData);

    //upload image
    let imageId;
    let formData = new FormData();
    formData.append("files", imgData);

    const requestOptions = {
      method: "POST",
      headers: {
        "Authorization": getTokenJWT(),
      },
      body: formData,
      redirect: "follow",
    };

    let data;
    try {
      const response = await fetch(`${window.$apiUrl}/upload`, requestOptions);
      const result = await response.json();

      imageId = result[0]._id;
      data = await this.updateSP(oldData, newData, imageId).then((result) => {
        return result;
      });
    } catch(error) {
      console.error(" Error updating publication -->", error)
    }

    return data;
  },

  updateSP(oldData, newData, imageId) {
    //Headers
    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Authorization", getTokenJWT())

    //Body
    let urlencoded = new URLSearchParams();
    urlencoded.append("rowId", JSON.stringify(newData.tableData));
    urlencoded.append("date", newData.date);
    urlencoded.append("publicationType", newData.publicationType);
    urlencoded.append("authors", newData.authors);
    urlencoded.append("publicationTitle", newData.publicationTitle);
    urlencoded.append("journalName", newData.journalName);
    urlencoded.append("publicationDOI", newData.publicationDOI);
    urlencoded.append("zenodoLink", newData.zenodoLink);
    urlencoded.append("photo", imageId);

    for (let i = 0; i < newData.partners.length; i++) {
      urlencoded.append("partners", newData.partners[i]);
    }

    for (let j = 0; j < newData.targetAudience.length; j++) {
      urlencoded.append("targetAudience", newData.targetAudience[j]);
    }

    const requestOptions = {
      method: "PUT",
      headers: headers,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      `${window.$apiUrl}/scientific-publications/` + oldData._id,
      requestOptions
    )
      .then((response) => {return response.json()})
      .then((result) => {
        if (result.statusCode && result.error) {
          console.error(" Error updating publication -->", result);
          ErrorToast(result.error);
          return;
        } else return result;
      })
      .catch((error) => console.error(" Error updating publication -->", error));
  },
};

export default updateSP;
