import { getTokenJWT } from "../../utils";

//Get all scientific publications
const getCA = {
  getCA() {
    return fetch(`${window.$apiUrl}/communications?_limit=-1`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": getTokenJWT(),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonReponse) => {
        return jsonReponse;
      })
      .catch((error) => console.error(" Error retrieving communication activity -->", error));
  },
};

export default getCA;
