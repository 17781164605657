import getEM from "./getEM";
import { getTokenJWT } from "../../utils";

const deleteEM = {
  //Delete row data from database
  deleteEM(oldData) {
    fetch(`${window.$apiUrl}/meetings/` + oldData.id, {
      method: "DELETE",
      headers: {
        "Authorization": getTokenJWT(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.deleteImage(oldData);
      })
      .catch((error) => console.error(" Error deleting event -->", error));
  },

  //Delete image from media library
  deleteImage(oldData) {
    fetch(`${window.$apiUrl}/upload/files/` + oldData.photo._id, {
      method: "DELETE",
      headers: {
        "Authorization": getTokenJWT(),
      },
    })
      .then((res) => res.json())
      .then((res) => res)
      .catch((error) => console.error(" Error deleting image -->", error));
  },

  async deleteFileImage(oldImgData) {
    let dataEM = await getEM.getEM().then((result) => {
      return result;
    });

    for (let i = 0; i < dataEM.length; i++) {
      let data = dataEM[i];

      if (data.photo !== null) {
        if (data.photo.name === oldImgData.path) {
          this.deleteEM(data);
        }
      }
    }
  },
};

export default deleteEM;
