import React, { Component } from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import "./Dropdown.css";

export default class DropdownItem extends Component {
  render() {
    return (
      <div className="dropdown-item">
        {this.props.link ? <Link
          to={this.props.link}
          variant="inherit"
          color="inherit"
          underline="none"
          component={NavLink}
        >
          {this.props.children}
        </Link> : <></>}

        {this.props.externalLink ? <Link
          href={this.props.externalLink}
          variant="inherit"
          color="inherit"
          underline="none"
          target="_blank"
        >
          {this.props.children}
        </Link> : <></>}
      </div>
    );
  }
}