import { getTokenJWT } from "../../utils";

const deleteSP = {
  //Delete row data from database
  deleteSP(oldData) {
    fetch(`${window.$apiUrl}/scientific-publications/` + oldData._id, {
      method: "DELETE",
      headers: {
        "Authorization": getTokenJWT(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.deleteImage(oldData);
      })
      .catch((error) => console.error(" Error deleting publication -->", error));
  },

  //Delete image from media library
  deleteImage(oldData) {
    fetch(`${window.$apiUrl}/upload/files/` + oldData.photo._id, {
      method: "DELETE",
      headers: {
        "Authorization": getTokenJWT(),
      },
    })
      .then((res) => res.json())
      .then((res) => res)
      .catch((error) => console.error(" Error deleting image -->", error));
  },
};

export default deleteSP;
